import type { ReactNode, ReactElement } from 'react'
import { cloneElement } from 'react'

import styled from 'styled-components'

import type { ColorProps } from '../types/props'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ColorName } from 'theme'
import { palette, fonts, getColor } from 'theme'

export type LocalFieldProps = {
    children:
        | (({ fieldElementProps }: { fieldElementProps: FieldElementProps }) => ReactNode)
        | ReactElement<any>
    label?: string | ReactElement<any>
    state?: ColorProps
    validationText?: string
    hint?: string
    isOptional?: boolean
    isRequired?: boolean
    a11yId?: string
    hideValidationText?: boolean
}
export type FieldElementProps = Pick<LocalFieldProps, 'a11yId' | 'isRequired' | 'state'>

type FieldLabelProps = {
    label: string | ReactElement<any>
    isOptional?: boolean
    a11yId?: string
}

export type FieldProps = LocalFieldProps & Omit<BoxProps, 'children'>

export const FieldLabel = ({ label, a11yId, isOptional }: FieldLabelProps) => (
    <FieldLabelContainer>
        {typeof label === 'string' ? <Label htmlFor={a11yId}>{label}</Label> : label}
        {isOptional && <OptionalText>OPTIONAL</OptionalText>}
    </FieldLabelContainer>
)

const FieldLabelContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
`

const Field = ({
    children,
    label,
    state,
    validationText,
    hint,
    isOptional,
    isRequired,
    a11yId,
    hideValidationText,
    ...props
}: FieldProps) => {
    const fieldElementProps: FieldElementProps = {
        isRequired,
        state,
        a11yId,
    }

    return (
        <_Field {...props}>
            {!!label && <FieldLabel label={label} isOptional={isOptional} a11yId={a11yId} />}

            {typeof children === 'function'
                ? children({ fieldElementProps })
                : cloneElement(children as ReactElement<any>, fieldElementProps)}

            {typeof hint === 'string' ? <Hint>{hint}</Hint> : <div>{hint}</div>}

            {!!validationText && !hideValidationText && (
                <ValidationText $color={state}>{validationText}</ValidationText>
            )}
        </_Field>
    )
}

export const fieldDefaultProps: Partial<FieldProps> = {
    isRequired: undefined,
    isOptional: undefined,
    label: undefined,
    validationText: undefined,
    state: undefined,
    a11yId: undefined,
}

Field.defaultProps = fieldDefaultProps

export default Field

export const Label = styled.label`
    display: block;
    font-weight: ${fonts.weight.semibold};
    font-size: ${fonts.size.S};
    line-height: 1;
    margin-bottom: 0;
    color: ${palette.neutral[700]};
    margin-bottom: 0.5rem;
`

export const Hint = styled.span`
    display: block;
    font-weight: ${fonts.weight.semibold};
    font-size: ${fonts.size.XXS};
    margin-top: 0.5rem;
    color: ${palette.neutral[700]};
`

export const OptionalText = styled.span`
    display: block;
    text-transform: uppercase;
    font-size: ${fonts.size.XXS};
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
`

export const ValidationText = styled.span<{ $color?: ColorProps }>`
    display: block;
    font-weight: ${fonts.weight.semibold};
    font-size: ${fonts.size.XXS};
    margin-top: 0.25rem;
    color: ${props => getColor(props.$color as ColorName)};
`

type _FieldProps = BoxProps & Pick<LocalFieldProps, Exclude<keyof LocalFieldProps, 'children'>>

export const _Field = styled(Box)<_FieldProps>`
    font-size: 1rem;
`
