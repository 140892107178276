import type { FocusEventHandler, FormEventHandler, ReactElement } from 'react'

import type { FieldProps } from 'formik'
import styled, { css } from 'styled-components'

import type { SizeProps, ColorProps } from '../types/props'

import type { BoxProps } from 'components/Primitives/Box'
import Box, { shouldForwardProp } from 'components/Primitives/Box'
import type { ColorName } from 'theme'
import { fonts, getColor, palette, radii, zIndex } from 'theme'

type LocalInputProps = {
    state?: ColorProps
    size?: SizeProps
    after?: ReactElement<any>
    type?: string
    value?: string
    placeholder?: string
    readOnly?: boolean
    disabled?: boolean
    field?: FieldProps
    onChange?: FormEventHandler<HTMLInputElement>
    onBlur?: FocusEventHandler<HTMLInputElement>
    onFocus?: FocusEventHandler<HTMLInputElement>
}

export type InputProps = LocalInputProps & BoxProps

const Input = ({
    className,
    after,
    type = 'text',
    value,
    placeholder,
    readOnly,
    ...props
}: InputProps) => {
    const { state, size = 'medium', ...nonTransientStyleProps } = props

    return (
        <InputWrapper size={size}>
            {!!after && (
                <Box
                    position="absolute"
                    right="0.5rem"
                    zIndex={zIndex.layerBase}
                    display="inline-flex"
                    color="neutral.400"
                    fontSize={fonts.size.XXS}
                >
                    {after}
                </Box>
            )}
            <_Input
                as="input"
                className={className}
                placeholder={placeholder}
                type={type}
                readOnly={readOnly}
                value={value}
                state={state}
                size={size}
                {...nonTransientStyleProps}
            />
        </InputWrapper>
    )
}

export default Input

const inputWrapperSizeProperties: { [key: string]: any } = {
    small: css`
        font-size: ${fonts.size.XS};
    `,
    medium: css`
        font-size: ${fonts.size.S};
    `,
    large: css`
        font-size: ${fonts.size.XL};
    `,
}

export type InputStyledProps = LocalInputProps

export const _BaseInput = css<InputStyledProps>`
    font-weight: ${fonts.weight.normal};
    border: 1px solid;

    background-color: ${palette.neutral[0]};
    box-shadow: none;
    border-radius: ${radii.default};
    color: ${palette.neutral[700]};
    min-height: 2rem;
    width: 100%;
    padding: 0.75rem 1rem;
    ${props =>
        props.readOnly &&
        css`
            color: ${palette.neutral[400]};
        `}
    &[disabled] {
        opacity: 0.8;
        background: ${palette.neutral[100]};
        border-color: ${palette.neutral[100]};
        &:hover {
            border-color: ${palette.neutral[100]};
        }
    }
    &:focus,
    &:active {
        outline: none;
        z-index: ${zIndex.layer02};
        box-shadow: none;
    }
    &::placeholder {
        color: ${palette.neutral[400]};
        font-weight: ${fonts.weight.medium};
    }

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
`

const InputWrapper = styled(Box)<{ size: SizeProps }>`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    & {
        ${props => inputWrapperSizeProperties[props.size]}
    }
`

const inputSizeProperties: { [key: string]: any } = {
    small: css`
        font-size: ${fonts.size.XXS};
        min-height: 1rem;
    `,
    medium: css`
        font-size: ${fonts.size.S};
    `,
    large: css`
        font-size: ${fonts.size.L};
        min-height: 2.5rem;
    `,
}

const _Input = styled.div.withConfig({
    shouldForwardProp: prop => shouldForwardProp(prop),
})<InputStyledProps>`
    & {
        ${_BaseInput}
    }
    color: ${palette.neutral[999]};
    & {
        ${props =>
            props.after &&
            css`
                padding-right: 2rem;
            `};
        ${props => props.size && inputSizeProperties[props.size]};
        border-color: ${props => {
            return props.state ? getColor(props.state as ColorName) : palette.neutral[200]
        }};
    }
`
