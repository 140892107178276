import React from 'react'

import type { Omit } from '../../types'
import type { LocalFieldProps } from '../Field'
import Field from '../Field'

import type { InputProps } from './Input'
import Input from './Input'

export type LocalInputFieldProps = Omit<LocalFieldProps, 'children'>
export type LocalInputProps = Omit<InputProps, 'size'>
export type InputFieldProps = LocalInputFieldProps & LocalInputProps

const InputField = (allProps: InputFieldProps) => {
    const {
        a11yId,
        label,
        isOptional,
        validationText,
        after,
        className,
        type,
        value,
        isRequired,
        state,
        hint,
        placeholder,
        hideValidationText,
        ...props
    } = allProps

    return (
        <Field
            a11yId={a11yId}
            label={label}
            hint={hint}
            isOptional={isOptional}
            isRequired={isRequired}
            state={state}
            validationText={validationText}
            hideValidationText={hideValidationText}
            {...props}
        >
            {({ fieldElementProps }) => (
                <Input
                    after={after}
                    className={className}
                    value={value}
                    type={type}
                    placeholder={placeholder}
                    {...props}
                    {...fieldElementProps}
                />
            )}
        </Field>
    )
}

InputField.displayName = 'InputField'

export default InputField
