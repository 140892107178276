export type NewsletterEdition = 'American Edition' | 'Global Edition' | 'European Edition'

export type NewsletterEditions = {
    [key: string]: {
        name: NewsletterEdition
        icon: string
    }
}

export type ListTypes = 'Main' | 'Staging' | 'Premium Newsletter'

export const newsletterEditions: NewsletterEditions = {
    global: {
        name: 'Global Edition',
        icon: '🌎',
    },
    american: {
        name: 'American Edition',
        icon: '🇺🇸',
    },
    european: {
        name: 'European Edition',
        icon: '🇪🇺',
    },
}
