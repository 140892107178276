import React from 'react'

const FIELDS = ['InputField', 'CheckboxField', 'SelectField']

//Todo other field types - select/radio

export const bindFns =
    (...fns: Array<Function>) =>
    (...args: any) => {
        fns.forEach(fn => fn && fn(...args))
    }

const WithFormikField = (Component: any) => {
    const WrappedFormikField = ({ field, form, ...props }: any) => {
        if (FIELDS.includes(Component.displayName)) {
            const fieldHasErrored =
                form.touched[field.name] && form.errors[field.name] && form.submitCount > 0

            // Handle states
            let state = fieldHasErrored ? 'danger' : undefined
            if (props.state) {
                state = props.state
            }

            // Handle error messages
            let validationText = fieldHasErrored ? form.errors[field.name] : undefined
            if (props.validationText) {
                validationText = props.validationText
            }

            const onBlur = field.onBlur
            // bind the onChange handlers together
            const onChange = bindFns(field.onChange, props.onChange)

            const inputProps = {
                state,
                validationText,
                onBlur,
                onChange,
            }

            return <Component {...props} {...field} {...inputProps} />
        }

        return <Component {...props} />
    }

    WrappedFormikField.displayName = `WithFormikField(${Component.displayName})`

    return WrappedFormikField
}

WithFormikField.displayName = 'WithFormikField'

export default WithFormikField
