export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.'

/**
 * getMessageFromUnknownError - function to get a message from an error, or provide a fallback message if the former is unavailable.
 * @param error - any type, as error in catch block is of unknown type, but we expect an object or an instance of Error type
 * @param defaultMessage - (Optional) default message to show when error message is empty or not available, default value is DEFAULT_ERROR_MESSAGE
 * @returns - error message as string
 */

export const getMessageFromUnknownError = (
    error: any,
    defaultMessage: string = DEFAULT_ERROR_MESSAGE,
): string => {
    return error?.message || defaultMessage
}
